import React from "react"

import toaster from "toasted-notes"

function Notification({ message, error, onClose }) {
  return (
    <div className="bg-white shadow-lg border-2 border-gray-800 w-full max-w-3xl p-3">
      <div className="text-left">
        <p className="pb-3 text-gray-900">
          To enrich and perfect your online experience, NMC Soft uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
          For more information click <a href="/privacy-policy/#cookies" className="text-blue-700 hover:text-blue-800" title="Cookies" aria-label="Cookies">here</a>.
        </p>
        <div className="flex justify-end">
          <button
            className="inline-block bg-primary-dark text-gray-200 font-bold text-lg leading-7 px-5 py-2 hover:text-white hover:bg-black focus:bg-black focus:outline-none"
            aria-label="Accept"
            onClick={() => {
              localStorage.setItem("cookie_consent", Date.now());
              onClose();
            }}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  )
}


function CookieToast() {
  React.useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookie_consent');
    if (!cookieAccepted) {
      toaster.notify(props => {
        return <Notification {...props} />
      }, {
        duration: null,
        position: "bottom-left",
      })
    }
  }, [])

  return null;
}

export default CookieToast;
