/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import classnames from 'classnames'

import Header, { HeaderMask } from "./header"
import Footer from "./footer"
import ProjectTag, { ProjectTagWrapper } from "./ProjectTag"

import "typeface-ibm-plex-sans"
import "./layout.css"
import CookieToast from "./CookieToast"

const heroScreens = ["/", "/company/", "/jobs/"]
function hasHeroScreen(path) {
  return heroScreens.includes(path)
}

const shortcodes = {
  a: props => (
    <a
      {...props}
      className={classnames("text-blue-700 hover:text-blue-800", props.className)}
    >
      {props.children}
    </a>
  ),
  ProjectTag,
  ProjectTagWrapper,
}

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="min-h-screen flex flex-col">
      <HeaderMask hide={hasHeroScreen(location.pathname)} />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="flex-grow">
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
      <Footer siteTitle={data.site.siteMetadata.title} />
      <CookieToast />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
