import React from 'react';


export function ProjectTagWrapper(props) {
  return (
    <div {...props} className="flex flex-wrap pt-5 pb-1" />
  )
}

/**
 * 
 * @param {{title: string}} param0 
 */
function ProjectTag({ title }) {
  return (
    <span className="inline-block px-3 py-2 border border-gray-200 bg-gray-100 text-sm text-gray-800 font-medium mr-4 mb-4">{title}</span>
  )
}


export default ProjectTag;
