import { Link } from "gatsby"
import React from "react"
import { useTransition, animated } from "react-spring"

import MenuIcon from "../svg/menu.svg"
import MenuCloseIcon from "../svg/close.svg"
import Logo from "../svg/nmc-soft-logo-icon.svg"

/**
 *
 * @param {{ hide: boolean }} param0
 */
export function HeaderMask({ hide }) {
  return <div className={`h-16 sm:h-20 ${hide ? "hidden" : ""}`} />
}

function DesktopNavItem({ badge, ...props }) {
  return (
    <Link
      className="relative inline-block px-3 py-3 text-base leading-7 hover:text-primary"
      activeClassName="text-primary"
      {...props}
    >
      {badge && (
        <span className="absolute top-0 right-0 leading-tight text-xs font-bold bg-pink-100 rounded-full px-1 text-pink-800">
          {badge}
        </span>
      )}
      {props.children}
    </Link>
  )
}

function MobileNavItem({ badge, ...props }) {
  return (
    <Link
      className="flex items-center px-4 py-1 text-base leading-7 hover:text-primary"
      {...props}
    >
      <span>{props.children}</span>
      {badge && (
        <span className="ml-3 leading-tight text-xs font-bold bg-pink-100 rounded-full px-1 text-pink-800">
          {badge}
        </span>
      )}
    </Link>
  )
}

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const toggleMenu = React.useCallback(() => {
    setMenuOpen(value => !value)
  }, [])

  const transitions = useTransition(menuOpen, null, {
    config: { mass: 1, tension: 500, friction: 20, clamp: true },
    from: { opacity: 0, transform: "translate3d(0, 40px, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(0, 40px, 0)" },
  })

  return (
    <div className="fixed bg-white w-full left-0 top-0 z-50">
      <div className="bg-gray-900 text-white text-center px-4 py-2">
        <p className="text-xs sm:text-sm font-semibold">SALES: <a href="tel:00355696088845">+355 69 608 8845</a> (MON - FRI 09:00 - 18:00 Phone/Whatsapp)</p>
      </div>
      <header className="border-b border-gray-200">
        <div className="container h-16 sm:h-20 flex justify-between items-center">
          <div className="flex items-center">
            <Link
              to="/"
              className="text-primary-dark hover:text-primary transition-colors duration-200 ease-in"
              title={siteTitle}
            >
              <Logo className="h-8 w-auto block fill-current sm:h-12" />
            </Link>
          </div>
          <div className="-mx-2 lg:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 text-gray-900 focus:text-black focus:bg-gray-100 focus:outline-none"
              aria-label="Open Menu"
            >
              {!menuOpen ? (
                <MenuIcon className="fill-current w-6 h-6" />
              ) : (
                <MenuCloseIcon className="fill-current w-6 h-6" />
              )}
            </button>
          </div>
          <div className="hidden lg:flex items-center space-x-3">
            <nav className="space-x-1">
              <DesktopNavItem to="/company/">Company</DesktopNavItem>
              <DesktopNavItem to="/services/">Services</DesktopNavItem>
              <DesktopNavItem to="/googleworkspace/">
              Google Workspace
              </DesktopNavItem>
              <DesktopNavItem to="/cloud-solutions/">
                Cloud Solutions
              </DesktopNavItem>
              <DesktopNavItem to="/projects/">Our Work</DesktopNavItem>
              <DesktopNavItem to="/jobs/">Jobs</DesktopNavItem>
            </nav>
            <div>
              <Link
                className="inline-block px-3 py-1 text-lg leading-8 border font-medium border-primary-dark hover:text-white hover:bg-primary-dark focus:text-white focus:bg-black focus:outline-none duration-200 transition ease-in"
                to="/estimation/"
              >
                your project
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="lg:hidden">
        <div className="relative">
          {transitions.map(({ item, key, props }) => {
            return (
              item && (
                <animated.div
                  key={key}
                  style={props}
                  className="absolute left-0 w-full bg-white shadow-lg pb-2"
                >
                  <div className="container">
                    <div className="space-y-4 py-4 -mx-4">
                      <nav className="space-y-2">
                        <MobileNavItem to="/company/" onClick={toggleMenu}>
                          Company
                        </MobileNavItem>
                        <MobileNavItem to="/services/" onClick={toggleMenu}>
                          Services
                        </MobileNavItem>
                        <MobileNavItem to="/googleworkspace/" onClick={toggleMenu}>
                        Google Workspace
                        </MobileNavItem>
                        <MobileNavItem
                          to="/cloud-solutions/"
                          onClick={toggleMenu}
                         
                        >
                          Cloud Solutions
                        </MobileNavItem>
                        <MobileNavItem to="/projects/" onClick={toggleMenu}>
                          Our Work
                        </MobileNavItem>
                        <MobileNavItem to="/jobs/" onClick={toggleMenu}>
                          Jobs
                        </MobileNavItem>
                      </nav>
                      <div className="px-4">
                        <Link
                          className="inline-block px-4 py-1 text-lg leading-8 border font-medium border-primary-dark hover:text-white hover:bg-primary-dark focus:text-white focus:bg-black focus:outline-none duration-200 transition ease-in"
                          to="/estimation/"
                          onClick={toggleMenu}
                        >
                          your project
                        </Link>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Header
