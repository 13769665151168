/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


import React from 'react';
import Layout from './src/components/layout';

export const wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <Layout {...props}>{element}</Layout>
);

let offsetY = 80;
let getTargetOffset = function getTargetOffset(hash) {
  let id = window.decodeURI(hash.replace("#", ""));

  if (id !== "") {
    let element = document.getElementById(id);

    if (element) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let clientTop = document.documentElement.clientTop || document.body.clientTop || 0;
      return element.getBoundingClientRect().top + scrollTop - clientTop - offsetY;
    }
  }

  return null;
};

export const onInitialClientRender = function () {
  requestAnimationFrame(function () {
    let offset = getTargetOffset(window.location.hash);

    if (offset !== null) {
      window.scrollTo(0, offset);
    }
  });
};

export const shouldUpdateScroll = function (_ref) {
  let location = _ref.routerProps.location;
  let offset = getTargetOffset(location.hash);
  return offset !== null ? [0, offset] : true;
};