import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Logo from "../svg/nmc-soft-logo-icon.svg"
import LinkedIn from "../svg/linkedin.svg"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(relativePath: { eq: "mobile/footer-bg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <footer className="pb-16 pt-6 relative">
      <div className="absolute bottom-0 right-0 pointer-events-none md:hidden">
        <GatsbyImage
          image={data.mobileBackground.childImageSharp.gatsbyImageData}
          className="w-40 h-auto"
          alt=""
        />
      </div>
      <div className="relative">
        <div className="container flex flex-wrap justify-between items-center">
          <div className="w-full lg:w-3/5 sm:flex sm:items-center">
            <Link
              to="/"
              className="text-primary-dark hover:text-primary transition-colors duration-200 ease-in inline-flex items-center flex-shrink-0"
              title={siteTitle}
            >
              <Logo className="h-12 sm:h-24 flex-shrink-0 w-auto block fill-current" />
              <span className="sm:hidden font-bold text-1.5xl leading-7 ml-2 sm:ml-3">
                {siteTitle}
              </span>
            </Link>
            <div className="text-base leading-6 py-4 sm:ml-5">
              <div>
                <Link
                  to="/"
                  className="inline-flex sm:block md:mb-1 font-bold text-2xl leading-7 text-primary-dark hover:text-primary transition-colors duration-200 ease-in items-center"
                >
                  <span>{siteTitle}</span>
                </Link>
              </div>
              <div>
                <a
                  className="hover:text-blue-700"
                  href="https://maps.app.goo.gl/xwNxh6pbeRq1ECXC6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rruga Janos Hunyadi, Nd.1, Shkalla 1, Kati 6, Ap.19, Tiranë 1000
                </a>
              </div>
              <div>
                Email:{" "}
                <a
                  className="hover:text-blue-700"
                  href="mailto:online@newmedia.al"
                >
                  online@newmedia.al
                </a>
              </div>
              <div>
                Phone:{" "}
                <a className="hover:text-blue-700" href="tel:+355 67 40 52 561">
                  +355 67 40 52 561
                </a>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-auto md:flex md:justify-between md:items-center lg:flex-col lg:items-end">
            <div className="space-y-1 py-4 sm:flex sm:space-y-0 sm:space-x-1 sm:-mx-2">
              <a
                className="leading-7 block hover:text-blue-700 sm:px-1"
                href="/pdf/one-pager.pdf"
                target="_blank"
                rel="noreferrer"
              >
                One Pager
              </a>
              <Link
                className="leading-7 block hover:text-blue-700 sm:px-1"
                to="/code-of-conduct"
              >
                Code of Conduct
              </Link>
              <Link
                className="leading-7 block hover:text-blue-700 sm:px-1"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
              <Link
                className="leading-7 block hover:text-blue-700 sm:px-1"
                to="/privacy-policy/#cookies"
              >
                Cookies
              </Link>
            </div>
            <div className="flex space-x-4 py-4">
              <a
                className="text-gray-800 hover:text-primary transition-colors duration-200 ease-in"
                href="https://www.linkedin.com/company/nmc-soft/"
                title="NMC Soft"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn className="w-8 h-8 block fill-current" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
